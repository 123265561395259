import * as React from "react";

import Layout from "../components/layout";
import { graphql, navigate } from "gatsby";
import { ContactFormField } from "../components/contactFormField";
import { useContactForm } from "../hooks/useContactForm";
import { ContactFormCheckboxField } from "../components/contactFormCheckboxField";
import { useEffect } from "react";
import { COMPANY_EMAIL } from "../config";
import { EbookForm } from "../../netlify/functions/submit-ebook-form";

const Ebook = ({ location }) => {
  const [isSending, response, submitContactForm, reCaptchaElement] =
    useContactForm();

  useEffect(() => {
    if (response === "ok") {
      navigate(`/ebook-download`);
    }
  }, [response]);

  return (
    <Layout
      location={location}
      seoTitle="Ebook - Flota pod kontrolą"
      seoDescription="Pobierz bezpłatny ebook i dowiedz się jak kontrolować stan i wyposażenie floty powyżej 10 pojazdów dzięki prostej aplikacji."
    >
      <>
        <div className="container ins-page-ebook">
          <div className="page-header">
            <div className="jumbotron p-3 p-md-5 text-white rounded bg-dark">
              <div className="col-md-6 px-0">
                <h1 className="mt-5">Ebook</h1>
                <h4 className="mb-5">Flota pod kontrolą</h4>
                <p className="lead my-3">
                  Pobierz bezpłatny ebook i dowiedz się jak kontrolować stan i
                  wyposażenie floty powyżej 10 pojazdów dzięki prostej
                  aplikacji.
                </p>
                <a
                  href="#download-form"
                  className="btn btn-rounded btn-outline-light mb-5"
                >
                  Pobierz Ebook
                </a>
              </div>
            </div>
          </div>

          <section>
            <h2 className="section-title text-center mb-5">
              Z eBooka dowiesz się jak…
            </h2>
            <div className="row mb-5">
              <div className="col-md-7 offset-md-3">
                <div className="media">
                  <span className="landing-feature-icon"></span>
                  <h5 className="font-weight-normal">
                    kontrolować stan pojazdów
                  </h5>
                </div>
              </div>
              <div className="col-md-7 offset-md-3">
                <div className="media">
                  <span className="landing-feature-icon"></span>
                  <h5 className="font-weight-normal">
                    uniknąć nieplanowanych zakupów na załadunku
                  </h5>
                </div>
              </div>
              <div className="col-md-7 offset-md-3">
                <div className="media">
                  <span className="landing-feature-icon"></span>
                  <h5 className="font-weight-normal">
                    zapanować nad kompletem dokumentów
                  </h5>
                </div>
              </div>
              <div className="col-md-7 offset-md-3">
                <div className="media">
                  <span className="landing-feature-icon"></span>
                  <h5 className="font-weight-normal">
                    zarządzać uszkodzeniami i rozliczać szkody
                  </h5>
                </div>
              </div>
              <div className="col-md-7 offset-md-3">
                <div className="media">
                  <span className="landing-feature-icon"></span>
                  <h5 className="font-weight-normal">
                    utrzymać czystość w pojazdach
                  </h5>
                </div>
              </div>
            </div>
          </section>
          <section className="ins-page-section">
            <div className="row">
              <div className="col-md-6">
                <img
                  src="/images/ebook/ebook-mock.png"
                  alt="Ebook"
                  className="img-fluid"
                />
              </div>
              <div className="col-md-6 mt-4">
                <h2 className="mb-5" id="download-form">
                  <span className="text-primary">Pobierz ebook</span>{" "}
                  wypełniając&nbsp;formularz
                </h2>
                <form
                  method="POST"
                  action="/.netlify/functions/submit-ebook-form"
                  onSubmit={submitContactForm}
                >
                  <div className="row">
                    <ContactFormField<EbookForm>
                      className="col-12"
                      fieldName="fullName"
                    />
                    <ContactFormField<EbookForm>
                      className="col-12"
                      fieldName="companyName"
                    />
                  </div>
                  <div className="row">
                    <ContactFormField<EbookForm>
                      className="col-12"
                      fieldName="email"
                      type="email"
                    />
                    <ContactFormField<EbookForm>
                      className="col-12"
                      fieldName="phone"
                      required={false}
                    />
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <ContactFormCheckboxField<EbookForm> fieldName="gdprAgreement" />
                    </div>
                    <div className="col-sm-12">
                      <ContactFormCheckboxField<EbookForm> fieldName="commercialOfferAgreement" />
                    </div>
                  </div>
                  <div className="mb-3">{reCaptchaElement}</div>
                  {response === "notOk" && (
                    <div className="alert alert-danger mb-5" role="alert">
                      Wystąpił niespodziewany błąd podczas wysyłania formularza.
                      Skontaktuj się z nami: {COMPANY_EMAIL}
                    </div>
                  )}
                  <button
                    type="submit"
                    className="btn btn-primary mb-4"
                    disabled={isSending}
                  >
                    {isSending ? "Przygotowuję..." : "Pobierz"}
                  </button>
                </form>
              </div>
            </div>
          </section>
        </div>
        <section className="ins-page-section">
          <div className="ins-full-width-blue-bg pt-5">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 d-flex justify-content-center align-items-end">
                  <img
                    src="/images/ebook/pawel-cut.png"
                    alt="Paweł Konowoł"
                    className="img-fluid person-vertical"
                  />
                </div>
                <div className="col-sm-6 pt-5 pb-5">
                  <blockquote>
                    Polska branża transportowa zajmuje pozycję lidera w
                    transporcie drogowym w Unii Europejskiej. Aby tę pozycję
                    utrzymać, należy zwrócić uwagę na kwestię efektywnego
                    zarządzania dostępną flotą transportową i eliminowanie
                    niepotrzebnych kosztów. Technologie flotowe, takie jak
                    aplikacja Inspecto, pozwalają pomóc w codziennych trudach
                    firm. To kolejny element transportowej układanki, który
                    odpowiednio zagospodarowany, da spokojny sen niejednemu
                    właścicielowi firmy transportowej.
                  </blockquote>

                  <p className="font-weight-bold">
                    Paweł Konowoł, CEO Inspecto Sp. z o.o.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </Layout>
  );
};

export default Ebook;

export const query = graphql`
  query ($language: String!) {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
